<template>
  <div class="print_table" ref="print_table">
  <div class="print">
      <div class="big_title">
        <span>{{ tabName }}</span>
        <svg class="icon" aria-hidden="true" @click="printBtn">
          <use href="#icon-dayin_d"></use>
        </svg>
      </div>
      <div class="title">
        <span>{{ viewName }}</span>
        <div style="width: 150px">
          <span>{{ $t("label.view.sortview.number") }}</span>
          <el-select
            v-model="page"
            :placeholder="$t('label_tabpage_pleaseSelectz')"
            @change="handleSizeChange"
            style="width: 90px; height: 30px"
          >
            <el-option
              v-for="item in pageOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!--
      <p class='title-p'>{{this.tabName+'——'+this.viewName}} </p>-->
      <div ref="printTest" class="printTest" id="printTest">
        <!-- 表格 -->
        <table-panel
          class="text-center"
          ref="tablePanel"
          :border="true"
          :show-index="false"
          :showLock="showLock"
          :checked="false"
          :prefix="prefix"
          :obj-id="objId"
          :object-api="objectApi"
          :view-id="viewId"
          :view-modify="ismodifyFalg"
          :pageNum="pageNum"
          :table-attr="tableAttr"
          :page-obj="pageObj"
          :table-button="tableBtnList"
          :noDataTip="noDataTip"
          :style="{ width: '100%' }"
        ></table-panel>
      </div>
      <footer class="foot">
        <p>
          © 2008-2021 CloudCC Inc.{{
            $t(
              "label.allrightsreserved.allrightsreserved.customerServiceHotline"
            )
          }}：400-642-2008
        </p>
        <!-- 公司版权所有。保留所有权利。客户服务热线 -->
      </footer>
    </div>

  </div>
</template>
<script>
/**
 * 打印组件
 */
import { TablePanel } from "@/components/index";
import * as CommonObjApi from "./api";
import waterMark from "@/utils/waterMark/callMark.js";

//解决默认打印页面点击取消列表主页面水印回显问题
var beforePrint = function () {};
var afterPrint = function () {
  let element = document.getElementById("1default");
  if (element) {
    element.style.display = "none";
  }
};
if (window.matchMedia) {
  var mediaQueryList = window.matchMedia("print");
  mediaQueryList.addListener(function (mql) {
    if (mql.matches) {
      beforePrint();
    } else {
      afterPrint();
    }
  });
}
window.onbeforeprint = beforePrint;
window.onafterprint = afterPrint;

export default {
  components: { TablePanel },
  name: "PrintTable",
  data() {
    return {
      printDialog: this.printFlag,
      noDataTip: false,
      namaLableList: [],
      sum: "",
      pageOption: [
        {
          value: "50",
          label: "50",
        },
        {
          value: "100",
          label: "100",
        },
        {
          value: "250",
          label: "250",
        },
        {
          value: "500",
          label: "500",
        },
      ],
      page: this.$route.params.pageSize,
      tableBtnList: {
        title: "",
        buttonList: [],
      },
      pageObj: {
        dataList: [],
      },
      ismodifyFalg: "false",
      tableAttr: [],
      showLock: false,
      pageSize: this.$route.params.pageSize,
      pageNum: this.$route.params.pageNum,
      totalSize: 0,
      tabName: "",
      viewName: "",
      prefix: this.$route.params.prefix,
      objId: this.$route.params.objId,
      objectApi: this.$route.params.objectApi,
      viewId: this.$route.params.viewId,
      searchKeyWord: this.$route.params.searchKeyWord,
      tagIds: this.$route.params.tagIdsArr,
      exactSearchFlag: this.$route.params.exactSearchFlag,
      scroll: "",
      diogHeight: "", //水印默认高度
      num: 50,
    };
  },
  created() {
    CommonObjApi.getViewListRecently({
      obj: this.prefix,
      viewId: this.viewId,
    }).then((res) => {
      this.viewName = res.data.viewName;
      this.tabName = res.data.label;
      document.title = this.$setTitle(
        `${this.tabName}——${this.viewName}`,
        false
      );
    });
    this.getViewListHeader();
  },
  mounted() {
    this.initListFun(this.pageSize, this.pageNum);
  },
  methods: {
    printBtn() {
      // document.getElementsByClassName("printTest")[0].style.zoom = 0.82;
      window.print();
    },
    initListFun(n, pageNum) {
      let element = document.getElementById("1default");
      if (element) {
        element.style.display = "none";
      }
      let data = {
        obj: this.prefix,
        viewId: this.viewId,
        pageSize: n,
        pageNum: pageNum,
        sortField: "",
        sortDir: "",
        searchKeyWord: this.searchKeyWord !== "null" ? this.searchKeyWord : "",
        tagIds: this.tagIds !== "null" ? this.tagIds.replace(/&/g, ",") : "",
        conditionValues: "",
        ischangenum: "false",
        recordnums: n,
        isAllTag: "",
        campaignid: "false",
        isallsearch: "false",
        myself: "false",
        scrollId: "false",
        type: "",
        exactSearchFlag: this.exactSearchFlag,
      };
      CommonObjApi.getViewListData(data).then((res) => {
        this.pageObj.dataList = res.data.list;
        this.page = res.data.list.length;
        // 打印页面加水印scrollHeight
        this.diogHeight = this.page * 50 + 150;
        if (
          localStorage.getItem("openWater") == "true" &&
          document.getElementsByClassName("print_table")[0] &&
          this.$route.path.split("/")[1] === "print-table"
        ) {
          waterMark(
            this.$refs.printTest,
            document.getElementsByClassName("print_table")[0].clientWidth +
              "px",
            this.diogHeight + "px",
            "999999",
            "detail"
          );
        }
      });
    },
    // 下拉菜单
    handleSizeChange(val) {
      this.initListFun(val, "1");
    },
    // 获取视图列表表头
    getViewListHeader() {
      this.pictLoading = true;
      this.tableAttr = [];
      let params = {
        obj: this.prefix,
        viewId: this.viewId,
        tagIds: "",
        isAllTag: "",
        searchKeyWord: "",
        conditionValues: "",
      };

      CommonObjApi.getViewListHeader(params).then((res) => {
        if (
          this.objId === "cloudccPlanCost" ||
          this.objId === "cloudccRealCost"
        ) {
          //项目管理中（实际人工成本和预计人工成本）隐藏新建按钮
          this.addable = false;
        } else {
          this.addable = res.data.objServices.add;
        }
        this.tableAttr = res.data.headLabel;
        // this.tableAttrLen=res.data.headLabel.length;
        this.tableAttr.forEach((item, idx) => {
          // 名称、查找、主详、查找多选均可跳转
          // 事件及任务下的主题、名称、相关项可跳转
          if (
            item.schemefieldName === "name" ||
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR" ||
            item.schemefieldName === "subject" ||
            item.lookupObj === "user" ||
            item.schemefieldName === "whoid" ||
            item.schemefieldName === "relateid"
          ) {
            this.$set(this.tableAttr[idx], "click", "detail");
          }

          // 项目管理-实际工作清单点击日期跳转详情
          if (this.$cookies.get("activeTabObjId") === "projectworklist") {
            if (item.schemefieldType === "D") {
              this.$set(this.tableAttr[idx], "click", "detail");
            }
          }
          this.$set(item, "colwidth", "");
          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
        });

        // this.$refs.tablePanel.changeTableAttr(this.tableAttr);
        setTimeout(() => {
          this.pictLoading = false;
        }, 1000);
      });
    },
  },
  watch: {},
};
</script>

<style lang="scss" rel="stylesheet/scss" media="print" scoped>
@media print {
  svg {
    display: none;
  }
  .big_title {
    display: none !important;
  }
  .title {
    display: none !important;
  }
  span {
    display: none !important;
  }
}
// @page { size: landscape; }
.printTest {
  ::v-deep .is-leaf {
    width: 100%;
    border-top: 1px solid #ebeef5;
    span {
      color: #000;
    }
  }
  .el-table {
    width: 100%;
    //border-bottom: 1px solid #DDDBDA;
    border: 1px solid #dddbda;
  }
  ::v-deep .el-table .cell,
  ::v-deep .tableHeadField {
    width: 100%;
    white-space: normal;
    padding-right: 0;
    cursor: auto;
    pointer-events: none;
  }
  ::v-deep .el-table .cell {
    background-clip: padding-box;
    font-size: 12px;
    overflow: inherit;
  }
  ::v-deep .el-table .cell > span,
  ::v-deep .el-table .cell > a {
    white-space: normal;
  }
  ::v-deep .tableHeadField span {
    font-size: 12px;
    color: #080707;
  }
  ::v-deep .allow-click,
  .cannotEdit {
    color: #080707;
  }
  ::v-deep .el-table .cell .el-tag {
    margin-bottom: 5px;
  }
  ::v-deep tr td a,
  ::v-deep tr td span,
  ::v-deep .el-tooltip div {
    font-size: 12px;
  }
  ::v-deep .el-table tbody tr:hover > td,
  ::v-deep .hover-row > td {
    background-color: #ffffff;
    // background-clip: padding-box;
  }

  ::v-deep .el-table tr:nth-child(1) {
    //padding: 10px;
    // border-top: 1px solid #dddbda;
    border-top: 1px solid #000;
  }

  ::v-deep .editIconImg {
    display: none;
  }
}
body {
  width: 100%;
}
.print_table {
  padding: 0 10px;
}
.title-p {
  display: none;
}
.big_title svg {
  width: 20px;
  cursor: pointer;
  height: 20px;
  margin-top: 16px;
}
.big_title {
  border-bottom: 1px solid #dddbda;
  height: 48px;
  span {
    display: inline-block;
    line-height: 48px;
  }
}
.big_title,
.title {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  span {
    font-weight: bolder;
  }
}
.title {
  margin: 20px 0;
  span {
    margin-right: 10px;
    font-size: 12px;
  }
  ::v-deep input {
    height: 30px;
  }
  ::v-deep .el-input__suffix {
    top: 5px;
    .el-input__icon {
      height: auto;
      line-height: 0;
    }
  }
}
::v-deep .printTest,
.el-table {
  width: 1175px !important;
  margin: 0 auto !important;
}
::v-deep .el-table th > .cell {
  word-break: break-word;
}

footer {
  margin-top: 20px;
  p {
    width: 100%;
    text-align: center;
    color: #080707;
  }
}
</style>
<style>
/*rel="stylesheet" media="print"*/

@media print {
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
    width: 100%;
  }
}
</style>