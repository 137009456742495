import axios from '@/config/httpConfig'

export function getApprovalHistoryList(param) {
    return axios.post('approval/getApprovalHistoryList', param)
}
// 获取详情页标签
export function GetBrief(data) {
    return axios.post('/objectdetail/getBrief', data)
}
//获取相关列表对象信息
export function GetRelationList(data) {
    return axios.post('/objectdetail/getRelationList', data)
}
//获取相关列表数据及按钮和字段信息
export function GetDetailRelatedItems(data) {
    return axios.post('/objectdetail/getDetailRelatedItems', data)
}

//产品详情页上价格手册条目查询
export function queryProductPricebookItems(data) {
    return axios.post('/pricebook/queryProductPricebookItems', data)
}

// 获取对象详细信息
export function getDetail(data) {
    return axios.post('/objectdetail/getDetail', data)
}

// 查找带值
export function getLookupRelatedFieldValue(data) {
    return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 查询动态布局规则
export function getDynamicLayoutListDetail(data) {
    return axios.post('/objectdetail/getDynamicLayoutListDetail', data)
}


// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 获取视图列表页面信息
export function getViewListRecently(data) {
    return axios.post('/view/list/getViewListRecently', data)
}

// 获取视图列表记录
export function getViewListData(data) {
    return axios.post('/view/list/getViewListAjax', data)
}

// 获取视图列表表头
export function getViewListHeader(data) {
    return axios.post('/view/list/getViewListHeader', data)
}

// 查询个案团队成员列表
export function queryCaseTeamMember(args) {
    return axios.post("caseteam/queryCaseTeamMember", args);
}