import watermark from "./firstMark"; //路径不要写错
// 添加水印的方法
function addWater(container,width,height,index,type) {
    //获取localStorage内部存储的水印信息
    let str1 = ''
    let data = JSON.parse(localStorage.getItem('vuex'))
    data = data.userInfoObj
    str1 = data.userName
    if(data.department){
        str1 += ` ${data.department}`
    }
    let str2 = ''
    str2 = data.userId;
    watermark.set(str1,str2, container,width,height,index,type);
}
export default addWater;