let watermark = {}

let setWatermark = (str,str1, container,width,height,index,type) => {
    let id = '1'+type
    if (container === undefined) {
        document.body.removeChild(document.getElementById(id));
    }
    //创建一个画布
    let can = document.createElement('canvas');
    //设置画布的长宽
    can.width = 230;
    can.height = 115;
    // 获取画笔
    let cans = can.getContext('2d');
    //旋转角度
    cans.rotate(-45 * Math.PI / 180);
    cans.font = '40 12px PingFangSC-Regular';
    //设置填充绘画的颜色、渐变或者模式
    cans.fillStyle = 'rgba(100,100,100,0.1)';
    //设置文本内容的当前对齐方式
    cans.textAlign = 'left';
    //设置在绘制文本时使用的当前文本基线
    cans.textBaseline = 'Middle';
    //在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
    cans.fillText(str, -20, can.height-5);
    cans.fillText(str1, -20, can.height+12);
    let div = document.createElement('div');
    div.id = id;
    div.style.pointerEvents = 'none';
    if(type=="default"){
        let left='-37px';
        let top="100px";
        div.style.top = top;
        div.style.left = left;
        div.style.position = 'fixed';
    }else if(type=="chart"){
        div.style.right='40px';
        div.style.bottom='17px';
        div.style.position = 'fixed';
    }else if(type=="detail"){
        div.style.right='-13px';
        div.style.top='17px';
        div.style.position = 'absolute';
    }
    // 宽 高document.documentElement.clientHeight + 'px'
    div.style.width = width;
    div.style.height = height;
    div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';
    div.style.overflow = "hidden";
    // 层级
    div.style.zIndex=index
    container.appendChild(div);
    return id;
}

// 该方法只允许调用一次
watermark.set = (str,str1, container,width,height,index,type) => {
    let id = '1'+type
    let result = document.getElementById(id)
    if(result){
        result.parentNode.removeChild(result);
    }
    setWatermark(str,str1,container,width,height,index,type);
}
export default watermark;
